import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Imgix from "react-imgix"
import htmlToText from "html-to-text"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faFolderOpen } from "@fortawesome/free-regular-svg-icons"

import Layout from "../components/layout"

import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO />
    <section className="hero">
      <figure>
        <Img
          fluid={data.hero.childImageSharp.fluid}
          alt=""
          style={{ height: "100%" }}
        />
      </figure>
      {/* <div className="catch">
        <h1>MITTY.BLOG</h1>
        <p>山と写真、趣味のブログ</p>
      </div> */}
    </section>
    <section className="recentposts">
      <div className="container">
        <h2 className="bar">RECENT POSTS</h2>
        <div className="posts">
          {data.allMicrocmsBlog.edges.map(({ node }) => (
            <article className="post" key={node.id}>
              <Link to={`/blog/post/${node.slug}/`}>
                <figure>
                <Imgix
                  src={node.eyecatch.url}
                  sizes="(max-width: 1200px) 100vw, 1200px"
                  htmlAttributes={{
                     alt: "",
                  }}
                />
                </figure>
                <h3>{node.title}</h3>
                <div className="info">
                  <time dateTime={node.publishDate}>
                    <FontAwesomeIcon icon={faClock} />
                    {node.publishDateJP}
                  </time>
                  <div className="cat">
                    <FontAwesomeIcon icon={faFolderOpen} />
                    <ul>
                      {node.category.map(cat => (
                        <li className={cat.categorySlug} key={cat.id}>{cat.category}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                  <p>{`${htmlToText
                    .fromString(node.content, {
                      ignoreImage: true,
                      ignoreHref: true,
                    })
                    .slice(0, 60)}…`}
                  </p>
              </Link>
            </article>
          ))}
        </div>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMicrocmsBlog(
      sort: { order: DESC, fields: publishDate }
      skip: 0
      limit: 4
    ) {
      edges {
        node {
          title
          id
          slug
          publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
          publishDate
          content
          category {
            category
            categorySlug
            id
          }
          eyecatch {
            url
          }
        }
      }
    }
  }
`
